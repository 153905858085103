<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <!-- <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-account-box"
                                    solo
                                    dense
                                    v-model="customer"
                                    :items="customers"
                                    item-value="value"
                                    item-text="label"
                                    label="Customer Category"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Periode"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="End Periode"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    dense
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => getColour()"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-coat-rack"
                                    clearable
                                    solo
                                    dense
                                    v-model="coat_mass"
                                    :items="coat_masss"
                                    item-value="coat_mass"
                                    :item-text="item => 'AS' + item.coat_mass"
                                    label="Coat (AS - AZ)"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    dense
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    dense
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="descr"
                                    label="Colour"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    dense
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                        
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card id="sumScShipmentAnalysis" outlined height="420">
                    <v-card-text>
                        <h6 class="indigo-text m-0">Query Result: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 370px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="dataTabel"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="150"
                                    :divider="true"
                                    :light="true"
                                    disable-pagination
                                >        
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container2" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container3" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container4" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container5" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container6" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container7" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
            <v-col cols="12" xs="12" 
            sm="6" 
            md="4">
                <v-card outlined height="400">
                    <v-card-text>
                        <v-img
                        class="text-center mx-auto"
                        style="margin-top: 80px;"
                        lazy-src="@/assets/empty_data.png"
                        max-width="200"
                        src="@/assets/empty_data.png"
                        v-if="showDataDetail"
                        ></v-img>
                        <div id="container8" style="height: 370px; width: 100%;"></div>
                    </v-card-text> 
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"
import _ from "lodash"

export default {
    data() {
        return {
            selected: ['SC', 'FGPROD'],
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Sales Marketing Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'SC & FG Production Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            loading: false,
            saless: [],
            sales: '',
            customers: [
                {
                    label: 'All',
                    value: 'All'
                },
                {
                    label: 'KKA',
                    value: 'KKA'
                },
                {
                    label: 'Non KKA',
                    value: 'Non KKA'
                }
            ],
            customer: '',
            search: null,
            order_id: '',
            loadingOrder: false,
            items:[],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',     
            showData: true,
            showDataDetail: true,
            dataSc: [],
            dataWgt: [],
            header:[],
            dataTabel:[],
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.customer = 'All'
        await this.getBrand()
        await this.getCoatMass()
        await this.getGrade()
        await this.getThick()
        await this.getWidth()
        await this.getColour()
        await this.getQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        close(){
            this.dialog = false
        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/grade`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/thicks?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/width?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getColour(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/colour?brand=${this.brand ? this.brand : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.colours = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/quality?commodity_id=GLV`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        checkSelected(){
            if (this.selected.length > 2) {
                this.selected = []
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please max check 2 item",
                    visible: true
                };
            }
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            if (this.selected.length < 2 || this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please check 2 item or fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'customer': this.customer ? this.customer : '',
                'start': start,
                'end': end,
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            var dt1 = [];
            var dt2 = [];
            var dt3 = [];
            this.dataSc = []
            this.dataWgt = []

            var data1 = {}
            var data2 = {}
            var data3 = {}

            var label = ''
            var container = 'container1';
            var title = " Summary";

            switch (this.selected[0]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sr/analysis/sc-prod', reqBody, false, false, false)
                        if (respData.status === 200) {
                            label = 'SC & ';
                            this.dataSc = respData.data

                            for (var i = 0; i < respData.data.length; i++) {
                                dt1.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#70ad47",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                case 'FGPROD':
                        const respDataFGPROD = await backendApi.fetchCore('/v2/sr/analysis/sc-prod/indexProd', reqBody, false, false, false)
                        if (respDataFGPROD.status === 200) {
                            label = 'FG Production & ';
                            this.dataWgt = respDataFGPROD.data

                            for (var i = 0; i < respDataFGPROD.data.length; i++) {
                                dt1.push({ label: respDataFGPROD.data[i].periode , y: parseFloat(respDataFGPROD.data[i].total_wgt)});
                                dt3.push({ label: respDataFGPROD.data[i].periode , y: parseFloat(respDataFGPROD.data[i].total_wgt_awal)});
                            }

                            data1 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total FG Production",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#70ad47",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            data3 = {
                                type: "column",
                                click: this.onClick3,
                                name: "Total FG Prod Wgt Awal",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#a5a5a5",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                
                default:
                    label = '';
                    dt1 = [];
                    break;
            }

            switch (this.selected[1]) {
                case 'SC':
                        const respData = await backendApi.fetchCore('/v2/sr/analysis/sc-prod', reqBody, false, false, false)
                        if (respData.status === 200) {
                            label = label + 'SC';
                            this.dataSc = respData.data
                            for (var i = 0; i < respData.data.length; i++) {
                                dt2.push({ label: respData.data[i].periode , y: parseFloat(respData.data[i].total_wgt)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClick1,
                                name: "Total SC",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#fb9b05",
                                // yValueFormatString: "#,###,,,.##",
                            }
                        }
                    break;
                case 'FGPROD':
                        const respDataFGProd = await backendApi.fetchCore('/v2/sr/analysis/sc-prod/indexProd', reqBody, false, false, false)
                        if (respDataFGProd.status === 200) {
                            label = label + 'FG Production';
                            this.dataWgt = respDataFGProd.data
                            for (var i = 0; i < respDataFGProd.data.length; i++) {
                                dt2.push({ label: respDataFGProd.data[i].periode , y: parseFloat(respDataFGProd.data[i].total_wgt)});
                                dt3.push({ label: respDataFGProd.data[i].periode , y: parseFloat(respDataFGProd.data[i].total_wgt_awal)});
                            }

                            data2 = {
                                type: "column",
                                click: this.onClick2,
                                name: "Total FG Production",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#fb9b05",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            data3 = {
                                type: "column",
                                click: this.onClick3,
                                name: "Total FG Prod Wgt Awal",
                                indexLabel: "{y}",
                                indexLabelFontSize: 14,
                                // indexLabelFontColor: "#FFF",
                                showInLegend: true,
                                indexLabelPlacement: "inside",  
                                indexLabelOrientation: "vertical",
                                color: "#a5a5a5",
                                // yValueFormatString: "#,###,,,.##",
                            }

                            
                        }
                    break;
                
                default:
                    label = '';
                    dt2 = [];
                    break;
            }
            dt1 = this.fillMissingLabelsByYear(dt1, start, end)
            dt2 = this.fillMissingLabelsByYear(dt2, start, end)
            dt3 = this.fillMissingLabelsByYear(dt3, start, end)

            this.dataTabel = this.createTable()
            this.header = this.mapLevelTwo(this.dataTabel, 'Type')
            this.getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3);

            $('html, body').animate({
                scrollTop: $("#sumScShipmentAnalysis").offset().top
            }, 1200)

            this.showData = false

            this.$store.dispatch('setOverlay', false)

        },
        generateLabels(startLabel, endLabel) {
            const labels = [];
            let [startYear, startMonth] = [parseInt(startLabel.substring(0, 4), 10), parseInt(startLabel.substring(4, 6), 10)];
            const [endYear, endMonth] = [parseInt(endLabel.substring(0, 4), 10), parseInt(endLabel.substring(4, 6), 10)];

            while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
                const label = `${startYear}${startMonth.toString().padStart(2, '0')}`;
                labels.push(label);

                startMonth++;
                if (startMonth > 12) {
                    startMonth = 1;
                    startYear++;
                }
            }

            return labels;
        },
        fillMissingLabelsByYear(data, startLabel, endLabel) {
            // _.forEach(data, (dataset, key) => {
                // Extract year from the dataset key
                // const datasetYear = parseInt(key.split(" ")[1], 10);

                // Generate labels for the past 12 months
                const labels = this.generateLabels(startLabel, endLabel);
                
                // Get existing labels in the dataset
                const existingLabels = _.map(data, 'label');
                
                // Determine which labels are missing
                const missingLabels = _.difference(labels, existingLabels);

                // Add missing labels to the dataset with a value of null
                _.forEach(missingLabels, (label) => {
                    data.push({ label: label, y: 0 });
                });

                // Sort dataset by label to maintain chronological order
                data.sort((a, b) => a.label.localeCompare(b.label));
            // });

            return data;
        },
        createTable(){
            let periods = new Set(this.dataSc.map(item => item.periode).concat(this.dataWgt.map(item => item.periode)))

            let result = Array.from(periods).sort().reduce((acc, period) => {
                console.log(acc)
                console.log(period)
                let scItem = this.dataSc.find(item => item.periode === period);
                console.log(scItem)
                let wgtItem = this.dataWgt.find(item => item.periode === period);
                console.log(wgtItem)

                acc[0][period] = scItem ? this.$store.getters.convertToCurrencyUs(parseFloat(scItem.total_wgt)) : 0;
                acc[1][period] = wgtItem ? this.$store.getters.convertToCurrencyUs(parseFloat(wgtItem.total_wgt)) : 0;

                return acc;
            }, [
                { type: 'SC' },
                { type: 'FG Produksi' }
            ]);

            return result;
        },
        mapLevelTwo(data, customDesc){
            const header = [
                { text: customDesc, align: 'start', value: 'type', align: 'left' }
            ];
            for (const key in data[0]) {
                if (key !== 'type') {
                    header.push({ text: key, value: key, align: key.startsWith('202') ? 'left' : 'right' });
                }
            }
            return header;
        },
        getChart(dt1, dt2, container, title, label, data1, data2, dt3, data3){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [ data1, data2]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async onClick1(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sr/analysis/sc-prod/getScShipmentAnalysisDetailbySC', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;
                this.showDataDetail = false

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                        dt_detail.push({  label: respData.data.qCoatMass[i].coat_mass,  y: parseFloat(respData.data.qCoatMass[i].total_wgt), legendText: respData.data.qCoatMass[i].coat_mass });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by AS/AZ SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qGrade.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qGrade.length; i++) {

                        dt_detail.push({  label: respData.data.qGrade[i].grade_id,  y: parseFloat(respData.data.qGrade[i].total_wgt), legendText: respData.data.qGrade[i].grade_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Grade SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: parseFloat(respData.data.qQuality[i].total_wgt), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qColor.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qColor.length; i++) {

                        dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt), legendText: respData.data.qColor[i].color_name });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Color SC'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClick2(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sr/analysis/sc-prod/getScShipmentAnalysisDetailbyProd', reqBody, false, false, false)

            if (respData.status === 200) {
                console.log(respData.data);
                this.showDataDetail = false
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                        dt_detail.push({  label: respData.data.qCoatMass[i].coat_mass,  y: parseFloat(respData.data.qCoatMass[i].total_wgt), legendText: respData.data.qCoatMass[i].coat_mass });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by AS/AZ FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qGrade.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qGrade.length; i++) {

                        dt_detail.push({  label: respData.data.qGrade[i].grade_id,  y: parseFloat(respData.data.qGrade[i].total_wgt), legendText: respData.data.qGrade[i].grade_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Grade FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: parseFloat(respData.data.qQuality[i].total_wgt), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qColor.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qColor.length; i++) {

                        dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt), legendText: respData.data.qColor[i].color_name });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Color FG Production'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qStock.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qStock.length; i++) {

                        dt_detail.push({  label: respData.data.qStock[i].category,  y: parseFloat(respData.data.qStock[i].value), legendText: respData.data.qStock[i].category });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Stock Origin'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }


                this.$store.dispatch('setOverlay', false)
            }
        },
        async onClick3(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'periode': e.dataPoint.label,
                'tahun': e.dataPoint.label.substr(0, 4),
                'bulan': e.dataPoint.label.substr(4, 6),
                'customer': this.customer ? this.customer : '',
                'brand': this.brand,
                'coat': this.coat_mass,
                'grade': this.grade,
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'colour': this.colour ? this.colour : '',
                'quality': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sr/analysis/sc-prod/getScShipmentAnalysisDetailbyProd', reqBody, false, false, false)

            if (respData.status === 200) {
                this.showDataDetail = false
                var param = e.dataPoint.label;
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respData.data.qWidth.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qWidth.length; i++) {

                        dt_detail.push({  label: respData.data.qWidth[i].width,  y: parseFloat(respData.data.qWidth[i].total_wgt_awal), legendText: respData.data.qWidth[i].width });

                    }
                    var container_detail = 'container2';
                    var title_detail = 'by Width FG Production Wgt Awal'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qThick.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qThick.length; i++) {

                        dt_detail.push({  label: respData.data.qThick[i].thick,  y: parseFloat(respData.data.qThick[i].total_wgt_awal), legendText: respData.data.qThick[i].thick });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Thickness FG Production Wgt Awal'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qCoatMass.length; i++) {

                        dt_detail.push({  label: respData.data.qCoatMass[i].coat_mass,  y: parseFloat(respData.data.qCoatMass[i].total_wgt_awal), legendText: respData.data.qCoatMass[i].coat_mass });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by AS/AZ FG Production Wgt Awal'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qGrade.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qGrade.length; i++) {

                        dt_detail.push({  label: respData.data.qGrade[i].grade_id,  y: parseFloat(respData.data.qGrade[i].total_wgt_awal), legendText: respData.data.qGrade[i].grade_id });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Grade FG Production Wgt Awal '
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qQuality.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qQuality.length; i++) {

                        dt_detail.push({  label: respData.data.qQuality[i].quality_id,  y: parseFloat(respData.data.qQuality[i].total_wgt_awal), legendText: respData.data.qQuality[i].quality_id });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Quality FG Production Wgt Awal'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respData.data.qColor.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respData.data.qColor.length; i++) {

                        dt_detail.push({  label: respData.data.qColor[i].color_name,  y: parseFloat(respData.data.qColor[i].total_wgt_awal), legendText: respData.data.qColor[i].color_name });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Color FG Production Wgt Awal'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }


                this.$store.dispatch('setOverlay', false)
            }
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();
        }
    },
    watch: {
    }
}
</script>
<style scoped>
    .tr_datatable{
    background-color: #F5F7F8 !important;
    }
</style>